import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/enterprise_collaboration.jpg'
import person from '../../images/person.png'

const pageTitle = "Enterprise Collaboration";
const pageSlug = "enterprise-collaboration";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Accelerating workplace collaboration without being bound by location or tools.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    The nature of how we work is changing radically, leading many businesses to find better ways to manage 
                    collaboration and information.
                    </h3>
                  <p>Enterprise collaboration and productivity solutions have been evolving to enhance cooperation and 
                      productivity while simultaneously strengthening an organization's security. However modernizing the 
                      workplace takes a lot more than just selecting, and implementing the right technology. This strategy 
                      must extend beyond the existing technical configuration of tools to include adoption and change 
                      management. As well as operational governance to ensure that users can adopt the necessary items
                       to manage the new technology. A holistic strategy can provide a strong foundation for creating a 
                       modern digital workplace – enabling team members to work better together.</p>
                    <p>Not sure where to start? We can figure that out together.</p>
                   <Link to="/contact-us" className="button green">Talk with an expert</Link>
                   <span className="break"></span>
                   <h4>Enterprise Collaboration Services</h4>
                   <p>Let's work together to increase productivity, employee engagement, 
                       and collaboration across your organization. By using:</p>
                    <h6><b>CLOUD SERVICE ADOPTION & SUPPORT</b></h6>
                    <ul>
                        <li>Data Classification</li>
                        <li>Proactive reports</li>
                        <li>Workflow Improvement</li>
                        <li>Data classification</li>
                        <li>User account control</li>
                        <li>Modern Workplace Deployments</li>
                        <li>Skills Development & Training</li>
                    </ul>
                    <span className="break"></span>
                    <h6><b>DIGITAL WORKSPACE DEPLOYMENTS & SUPPORT</b></h6>
                    <ul>
                        <li>Content Migration</li>
                        <li>Content Management</li>
                        <li>Intranet Modernization</li>
                        <li>Skills Development & Training</li>
                        <li>Security & Compliance Checks</li>
                    </ul>
                      
                </div>
             {/*   <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default BusinessConsulting
